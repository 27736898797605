.home-hero {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 100vh;
  padding: 1rem;
}

.home_title {
  display: flex;
  text-transform: uppercase;
  line-height: 0.9;
  align-items: flex-end;
}

.home-left,
.home-right {
  margin: 2rem 1rem;
}

.home-left {
  font-size: 5rem;
}

.home-right {
  text-align: left;
  max-width: 40%;
  font-size: 2.95rem;
}

.strong a {
  /* border-bottom: 3px solid var(--black); */
  text-decoration: underline;
}

.second-div {
  background-image: url("https://www.zilliondesigns.com/blog/wp-content/uploads/Pattern-Logos.jpg");
  background-color: var(--lightGrey);
  display: flex;
  flex-direction: column;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
}

.third-div {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.third-div-logo {
  width: 100px;
  display: flex;
  align-self: center;
  justify-self: center;
}
.third-div-title {
  padding: 2rem;
  font-size: 25px;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .home-left {
    font-size: 3rem;
    margin-bottom: 0;
  }

  .home-right {
    margin-top: 0;
    text-align: left;
    max-width: 80vw;
    font-size: 2rem;
  }
  .second-div {
    background-attachment: scroll;
  }
}

/* Basic styling for the button */
.shiny-button {
  color: #000000;
  background: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.3s;
}

/* Adding the pseudo-element */
.shiny-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -110%; /* Start off-screen */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1.8) 50%,
    rgba(255, 255, 255, 0.75) 100%
  );
  transform: skewX(-25deg); /* Create the tilt effect */
  transition: all 0.5s;
  z-index: 1;
}

/* Ensure the text stays above the shine */
.shiny-button {
  z-index: 2;
  position: relative;
}

/* Shine animation on hover */
.shiny-button:hover::before {
  animation: shine 2s forwards;
}

/* Keyframes for the shine effect */
@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
