.main {
  min-height: 100vh;
}

.main-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  max-width: 70vw;
  margin: auto;
}
.about-title {
  font-size: 1.5rem;
}

.about-route {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
