.contact-main_title {
  padding: 2rem;
}

.contact_link {
  margin-top: 50px;
}
.contact_link a {
  font-weight: 700;
}

.admin-page {
  position: fixed;
  left: 50px;
  bottom: 30px;
  /* color: transparent; */
  color: var(--navbarBg);
  font-size: 2rem;
}
