html,
body {
  box-sizing: border-box;
  min-height: 100%;
}

body,
ul,
ol,
dl,
p {
  margin: 0;
  padding: 0;
}

article,
aside,
audio,
footer,
header,
nav,
section,
video,
img {
  display: block;
}

img {
  display: block;
  max-width: 100%;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  /* color: var(--white); */
  color: var(--black);
}

:root {
  --navbar: rgba(255, 255, 255, 0.8);
  --navbarBg: rgba(000, 000, 000, 0.1);
  --white: rgb(255, 255, 255);
  --black: #000000;
  --darkGrey: #1b1b1b;
  --grey: rgb(133, 132, 132);
  --lightGrey: rgba(193, 193, 193, 0.3);
  --boxShadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --green: rgb(87, 147, 87);
}

/* ================================================*/

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 5%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}
body {
  background-color: var(--darkGrey);
  color: var(--white);
}
.App-header {
  background-color: var(--white);
  color: var(--black);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
