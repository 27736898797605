.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 1rem;
  width: 95%;
  color: var(--black);
  background-color: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
  z-index: 100;
  font-size: 15px;
  font-weight: 400;
  overflow-x: none;
}

.navbar__right {
  gap: 10px;
  z-index: 110;
  /* padding-right: 10px; */
}

.title-logo {
  gap: 1rem;
  /* text-transform: uppercase; */
  display: flex;

  align-items: center;
  justify-content: center;
  list-style: none;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar__left,
.navbar__right {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.navbar__item {
  z-index: 10;
}
.navbar__item a {
  text-decoration: none;
  color: inherit;
  /* font-size: 15px; */
  /* font-weight: 400; */
}
.contact-img img {
  padding: 0;
}

.contact-img {
  /* display: none; */
  width: 25px;
}
.navbar__logo {
  width: 21px;
  padding: 10px;
}

.navbar__left a:hover,
.navbar__left a:focus,
.navbar__left--clicked {
  text-decoration: underline;
}

/* Media query for small screens */
@media (max-width: 768px) {
  /* .contact-txt {
    display: none;
  } */
  .contact-img {
    display: block;
  }
  .navbar__right {
    padding-right: 0.7rem;
  }
  .title-logo {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .navbar {
    justify-content: space-between;
  }
}
