/* .Developer-main_title {
  margin-top: 20%;
} */
.dev-container {
  width: 100%;
  /* height: 70vh; */
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 1rem;
}

.ws00 {
  margin: 0 auto;
  height: 200px;
  /* width: 30%; */
  /* width: 45%; */
}

iframe {
  width: 100%;
  height: 100%;
  border: 0.1px solid #000;
  /* border: none; */
}
