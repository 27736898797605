/* Admin.css */

.Admin {
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(10deg, var(--black) 50%, var(--white) 50%);
}

.Admin-main {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  transform: translate(-55%, -20%);
  rotate: 10deg;
}
.Admin-main h2 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 900;
}
.Admin-main__form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1vh;
}

.Admin-main__form input[type="email"],
.Admin-main__form input[type="password"] {
  border: none;
  outline: none;
}

.Admin-main__form input[type="email"],
.Admin-main__form input[type="password"],
.Admin-main__button[type="submit"] {
  padding: 1vh;
  border-radius: 7px;
  border: 0.1rem solid var(--darkGrey);
}

.Admin-main__form input[type="email"]:focus,
.Admin-main__form input[type="password"]:focus {
  outline: 2px solid var(--black);
}

.Admin-main__button[type="submit"] {
  margin-top: 1vh;
  padding: 1vh;
}

.Admin-main__button[type="submit"]:hover {
  cursor: pointer;
}

.Admin-container {
  margin: 60px auto;
}

.admin_icon {
  margin-top: 5%;
}

.Admin-main__button {
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

/* .Admin-main__button:hover {
  background-color: var(--black);
  color: var(--white);
} */

input[type="button"],
input[type="submit"],
button {
  /* -webkit-appearance: none; */
  /* appearance: none; */
  -webkit-appearance: unset;
  appearance: unset;
  color: var(--black);
}

.hidden-text {
  padding: 0.8rem 0.2rem;
  font-size: 16px;
}

.Admin__signout {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 1.5s;
}

/* Media query for small screens */
@media (max-width: 768px) {
  navbar {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .Admin-main__button:hover {
    background-color: var(--black);
    color: var(--white);
  }
}
