.ToDoList {
  background-color: var(--navbarBg);
}

.ToDoList-sub {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.ToDoList-sub__item {
  display: flex;
  /* background-color: white;
  color: black; */
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
}

/* .Admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} */
